<template>
	<div id="login">
		<login name="应急管理" topName="智能网联汽车综合云服务平台" :bg="loginBg" :logo="loginLogo" @login="login"></login>
	</div>
</template>
<script type="text/javascript">
import Login from '@/components/jyui/components/login';
import loginBg from '@/assets/login_background.png';
import loginLogo from '@/assets/login_logo.png';
import {setToken} from "@/utils/token";
export default {
	data() {
		return {
			loginBg,
			loginLogo,
		}
	},
	components: {
		Login
	},
	methods: {
		login(form) {
			this.$http({
                url: '/login',
                data: form
            }).then(res => {
				setToken(res.detail.token)
				setTimeout(() => {
                    this.$router.push({ name: 'main' })
					// this.$router.replace('/')
                }, 100)
			})
		}
	}
}

</script>
